import React from 'react';
import YouTube from 'react-youtube';
import '../App.css';

const YouTubePlayer = ({ videoId }) => {
  const opts = {
    height: '350px',
    width: '400px',
    iframeclassName: 'video-responsive-iframe',
    className: 'video-responsive',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

export default YouTubePlayer;
