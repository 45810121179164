import React from 'react';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import styled from 'styled-components';

const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const IconLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 2rem;

  &:hover {
    color: #555;
  }
`;

const SocialLinks = ({ facebookUrl, youtubeUrl }) => {
  return (
    <LinksWrapper>
      <IconLink href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <FaFacebook />
      </IconLink>
      <IconLink href={youtubeUrl} target="_blank" rel="noopener noreferrer">
        <FaYoutube />
      </IconLink>
    </LinksWrapper>
  );
};

export default SocialLinks;
