import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: #333;
  color: white;
  text-align: center;
  padding: .5rem;
  position: auto;
  bottom: 0;
  width: 100%;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p className="FooterText">&copy; 2024 2nd South Carolina String Band. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
