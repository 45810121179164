import React from 'react';
import styled from 'styled-components';
import Review from '../components/Review';
import SocialLinks from '../components/SocialLinks';
import HeaderSection from '../components/HeaderSection';

const Container = styled.div`
  padding: 2rem;
  text-align: center; // Center the content
  font-family: 'DM Serif Display', serif; // Apply the font here
`;



const Image = styled.img`
  max-width: 100%;
  height: auto;
 
`;

const reviews = [

  {
    name: 'Brenda Wilt. "Camp Music."',
    title: 'Civil War Times Illustrated',
    review: 'Just as camp musicians, or ministrel bands, were an essential part of camp life for Civil War soldiers, so they are for modern-day reenactors. One such talented ensemble, the 2nd South Carolina String Band, has produced a video called Far, Far from Home that brings to life not only the sounds, but also the sights of camp life… Filmed on location in Gettysburg, the video really creates the illusion of being in camp with the soldiers, listening to the songs of home.',
    rating: 5,
  },
  {
    name: 'Jim Kushlan. "Fresh from the Past."',
    title: 'Civil War Times Illustrated',
    review: 'Much like hearing Bach’s Brandenburg Concerti performed on period instruments according to a Baroque aesthetic, listening to Civil War songs played on genuine instruments of the period as they would have been in 1860s lets you hear the real sound of a time and place. Still, the real essence of a piece of music is not found in the make of the instruments, but in artful performance done with understanding.',
    rating: 5,
  },
  {
    name: 'Bob Flesher. "The Banjo in the Civil War."',
    title: 'Banjo NewsLetter',
    review: 'It’s beautiful, but with a slight rough edge on it for authenticity. Excellent vocals and harmony and real good instrumentation. The banjo player Joe Ewers plays an authentic 13″ tackhead banjo. And I love the use of the base drum.',
    rating: 5,
  },

  {
    name: 'Will Dennison. “Southern Soldier."',
    title: 'Camp Chase Gazette',
    review: 'A truly remarkable experience. The music is beautifully played and takes you back to the Civil War era.',
    rating: 5,
  },
  {
    name: 'Kathryn Miller Haines',
    title: 'Center for American Music, Stephen Foster Memorial',
    review: 'I’ve heard many interpretations of Foster’s songs over the years, but your group does a fantastic job both with the songs themselves and the intent behind them. I’m duly impressed. By doing such a remarkable job of capturing the joy and pathos of Foster’s lyrics, your performances transport the listener to another time…',
    rating: 5,
  },

  // Add more reviews as needed
];

const Music = () => {
  return (
    <Container>
      <div className="flex flex-col items-center h-auto bg-earthyGreen-light">
         <div className="xl:w-1/2 md:w-3/4 p-2 pb-12 bg-yellow-50 rounded shadow-lg">
          <div className="flex flex-col items-center">
 
            <div className="min-w-90 p-0">
              <Image className="lg:my-4 lg:rounded-lg" src="/civilwarmusic.jpg" alt="Band Photo" />
            </div>
            <div className="text-xl lg:px-4 px-4 lg:mx-8">
            <div className="pageTitle">Our Music</div>
            <div className="bg-amber-100 rounded-lg p-6 shadow-inner shadow-lg">
            <p className="mb-2 text-xl font-semibold text-center">You can purchase or stream our albums on most (if not all) digital platforms, including <a className="underline" href="https://amzn.to/3xDlU5Z">Amazon </a> 
            and <a className="underline" href="https://music.apple.com/us/artist/2nd-south-carolina-string-band/5004644">iTunes.</a></p>
            <p className="text-center pb-4">A DVD version of our Civil War music video <a className="underline" href="https://amzn.to/3RQuBRw">"Far, Far from Home"</a> is available through Amazon.com.</p>
        
            <div className="flex flex-wrap justify-center my-4">
<img className="albumpic" src="/2scsb5.jpg"></img> 
<img className="albumpic" src="/prod_hardroad_cd.jpg"></img> 
<img className="albumpic" src="/prod_southern_cd.jpg"></img> 
<img className="albumpic" src="/prod_cotton_cd.jpg"></img> 
<img className="albumpic" src="/dulcem.jpg"></img> 
<img className="albumpic" src="/strikethetent.jpg"></img> 
<img className="albumpic" src="/aintdeadyet_small.jpg"></img> 
            </div>



</div>

            <p className="lg:first-letter:text-5xl lg:first-letter:-mx-1 text-left pb-2">The songs and instrumental tunes performed by the 2nd South Carolina String Band would have been considered the “pop” music of the period beginning in the late 1820′s and running through the 1860′s and beyond. In the years following the American Revolution and the War of 1812, Americans were determined to reject European classical musical forms and were searching for their own distinctly American musical “voice.” They found it in the frontier tradition of tall-tales about larger-than-life American characters such as Davy Crockett, Paul Bunyon, Old Dan Tucker and John Henry. Composers such as Joel Sweeney, Daniel Emmett, Stephen Foster, and George Root soon arrived on the scene; men who wrote music for a living that appealed to the masses. </p>
            <p>This music was unique in that it had no classical background. Its roots were in Celtic, American and African folk melodies. Its songs were filled with the language, slang, and experiences of the common man rather than the intellectual elites and its impact on American culture echoes down to the present day. </p>
              <p>The 2nd South Carolina String Band plays the songs and music that moved the American people of the early and mid-eighteen hundreds. They play the music that was in the hearts and minds and on the tongues of the citizen-soldiers that made up the ranks of the armies of the North and the South as they marched off to take part in the cataclysmic struggle that was to become the defining event of our nation’s history. They play it on instruments of the era and in an authentic manner and style that carries the listener back to simpler times. They play with a verve and excitement that infects even the most reserved listener with their own enjoyment and brings back to vibrant life the tumultuous energy of the American experience during the War Between the States. To experience the 2nd South Carolina String Band is, for a moment, to reach out and touch the past – "to eavesdrop on history."</p>
            </div>
          </div>
        </div>
      </div>



      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl font-bold mb-4">Follow Us</h2>
        <SocialLinks 
          facebookUrl="https://www.facebook.com/2SCSB" 
          youtubeUrl="https://www.youtube.com/channel/UC6RHX6u-WDoS0EuMgIyfHrA" 
        />
      </div>

    </Container>
  );
};

export default Music;
