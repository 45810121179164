import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: auto;

`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Image = styled.img`
  display: flex;
  margin: 0.5rem 0;
  max-width: 50%;
  height: auto;

  @media (width: 90%) {
    margin-top: 0.5rem;
  }
`;

const TextContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const Button = styled(Link)`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #336699;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  &:hover {
    background-color: #5290cf;
  }
`;

const StoreContainer = () => {
  const images = [
    '/civilwarbandmug.png',
    '/civilwarbandshirt.png',
  ];

  return (
    <Container>
      <ImageContainer>
        <Image src={images[0]} alt="Band Photo 1" />
        <Image src={images[1]} alt="Band Photo 2" />
      </ImageContainer>
      <TextContainer className="flex flex-col items-center">
        <h3 className="text-lg w-3/4 font-semibold text-gray-600">2nd South Carolina String Band is happy to offer custom designed memorabilia to our friends and fans</h3>
        <Button className ="font-semibold" to="https://my-store-c023f7.creator-spring.com/">Visit the official 2nd SCSB store</Button>
      </TextContainer>
    </Container>
  );
};

export default StoreContainer;
