import React from 'react';
import styled from 'styled-components';
import SocialLinks from '../components/SocialLinks';
import HeaderSection from '../components/HeaderSection';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  font-family: 'DM Serif Display', serif;

 
`;

const Image = styled.img`
  display: block;
  margin: 0.5rem auto;
  margin-top: 1.5rem;
  max-width: 100%;
  height: auto;
    @media (max-width: 600px) {
    margin-top: 0.5rem;
  }
`;

const images = [
  '/2ndsouthbandphoto1.jpg',
  '/2ndsouthbandphoto2.jpg',
  '/2ndsouthbandphoto3.jpg',
  '/2ndsouthbandphoto4.jpg',
  '/2ndsouthbandphoto5.jpg',
];

const About = () => {
  return (
    <Container>
      <div className="flex flex-col items-center h-auto bg-earthyGreen-light">
      <div className="text-xl px-4 lg:px-12  xl:w-1/2 md:w-3/4 pb-12 bg-yellow-50 rounded shadow-lg">
   
          <Image className="lg:rounded-lg" src={images[0]} alt="Band Photo 1" />
          <div className="pageTitle">About Us</div>
          <div className="lg:first-letter:text-5xl lg:first-letter:-mx-1 text-left pb-2">
            The 2nd South Carolina String Band was formed in August of 1989 by five riflemen of Co.I, 2nd SC Volunteer Infantry, a unit of Civil War reenactors that was very active during the five years of events celebrating the 125th Anniversary of the Civil War – and for many years to follow.
          </div>
          <Image src={images[1]} alt="Band Photo 2" />

       
          <div className="text-left pb-2">
            After the battles, drills and inspections, the boys who had instruments played and sang around the campfire while members of the unit would often join in and sing along. This was the beginning of the 2nd South Carolina String Band. Without recognizing it at the time, the group, comprised of mostly amateur musicians playing banjo, fiddle, and guitar, tambourine, bones and military drum – had coalesced into a 20th century recreation of a typical American Civil War camp band.
          </div>
          <Image src={images[2]} alt="Band Photo 3" />
          <div className="text-left pb-2">
            In the beginning they played mostly at night around their company camp fire as they enthusiastically began to explore and perform the music of the War Between the States. Soon they began performing for reenactment dances and concert audiences.
          </div>
          <Image src={images[3]} alt="Band Photo 4" />
          <div className="text-left pb-2">
            More than three-and-a-half decades have passed since the summer they met, and the band’s popularity has grown within the Civil War reenactment community and far beyond. During the same period the group itself has grown and matured in their musicianship, sense of historical accuracy, and presentation.
          </div>
          <Image src={images[4]} alt="Band Photo 5" />
          <div className="text-left pb-2">
            The group has changed over time, adding new members and instruments – a second fiddle, fife, penny-whistle and flute. It now numbers seven regular members (with a few frequent guest artists) and they’re proud to say that four of the original five men are “still with the colors” – still proudly representing the much renowned but no longer active infantry company they once marched with.
          </div>
          <div className="text-left pb-2">
            Though not a performing member of the group, Erik Ewers (son of the band’s manager and banjo player, Joe, and nephew to Joe’s brother and fiddler, Fred) has been as much a part of the group’s success over the years as any of the musicians. Erik has been the producer of all five albums released by the 2nd South Carolina String Band. A reenactor himself in his college years (with his father, uncle, and younger brother, Chris), Erik joined documentarian Ken Burns as an intern at Florentine Films following graduation from the University of Massachusetts. He rose ‘through the ranks’ there and after more than 20 years with Burns, has won Emmys for film editing in ‘Unforgivable Blackness. The Rise and Fall of Jack Johnson,’ and for sound editing in ‘War’. It is his artistic instincts and keen ear that have been key to capturing in the studio the same unique spirit and energy that is the hallmark of the band’s live performances.
          </div>
          <div className="text-left pb-2">
            In more than 30 years together, the 2nd South Carolina String Band’s music has literally spread around the globe via the internet, radio, and TV airplay on folk music stations in Australia, the United Kingdom and Europe, plus many university radio and National Public Radio stations in the United States. They have been featured in periodicals as diverse as People Magazine, U.S.A. Today, Skirmish Magazine, and U.S. News & World Report.
          </div>
          <div className="text-left pb-2">
            Exposure for their music on Amazon.com‘s Civil War Music page, on CD Baby.com‘s powerful web distribution outlet for independent musicians, and on YouTube has played an important role in elevating their standing in the Civil War reenacting community world-wide as well as their reputation for quality among lovers of music in the population-at-large – be they attracted to historical, folk. traditional, or just plain old good music. All 5 of their recordings have been listed in the Top 30 selections on Amazon.com‘s Civil War Music page for the past 5 years running. The 5-Star ratings given to all 5 of their albums released to date by folks who’ve purchased them stand as testament to the group’s high standards of excellence and attention to detail.
          </div>
          <div className="text-left pb-2">
            The band is proud to be able to claim fans and supporters from virtually every country in Europe, as well as Canada, Australia, Japan, Korea, China, and more. Over the years they have worked with several filmmakers and documentarians, contributing period music to soundtracks for Ken Burns documentaries “Mark Twain”, the Emmy nominated “JAZZ”, and most recently, “The Roosevelts.” In 2002, the band was selected by director Ron Maxwell to provide background instrumental music for the soundtrack as well as to give a rousing on-screen performance in the Warner Brothers film, “Gods & Generals”. In November, 2004, at a benefit concert at Gordon College in Massachusetts, the group was awarded the prestigious Stephen Collins Foster Award in recognition of their contributions to the preservation of 19th century American arts and culture. At a Fall 2005 reenactment they were filmed for an upcoming episode of the British/Public Television travel show, Globe Trekker. On July 4th, 2006, they released their fourth CD, Dulcem Melodies, and in March of 2008, recorded their fifth, Lightning In A Jar, a live double album in Gettysburg, released later that year, also on July 4th at the annual Gettysburg Reenactment. They are presently at work on their sixth and final release (“… they ain’t writin’ any new Civil War music.”, says band leader and banjo player, Joe Ewers.
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl font-bold mb-4">Follow Us</h2>
        <SocialLinks 
          facebookUrl="https://www.facebook.com/2SCSB" 
          youtubeUrl="https://www.youtube.com/channel/UC6RHX6u-WDoS0EuMgIyfHrA" 
        />
      </div>
    </Container>
  );
};

export default About;
