import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Music from './pages/Music';
//  @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');



function App() {
  return (
    <Router>
      <div className="App  bg-earthyGreen-light ">
        <NavigationBar  />
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/music" element={<Music />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
