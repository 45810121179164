import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from './HeaderSection';

const Nav = styled.nav`
  background: #333;
  padding: .3rem;
  display: flex;
  font-family: 'DM Serif Display', serif;
  font-variant:all-small-caps;
  font-size: 1.4rem;
`;

const NavLink = styled(Link)`
  color: yellow-50;
  margin: 0 1rem;
  text-decoration: none;
  padding-left:1.6rem;
  padding-right:1.6rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
`;

const NavigationBar = () => {
  return (
    <Fragment >
      <HeaderSection />
    
    <Nav className="text-yellow-50 flex place-content-evenly lg:place-content-center">
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About Us</NavLink>
      <NavLink to="/music">Music</NavLink>
    </Nav>
    </Fragment>
  );
};

export default NavigationBar;
