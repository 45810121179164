import React from 'react';
import styled from 'styled-components';
import Review from '../components/Review';
import YouTubePlayer from '../components/YouTubePlayer';
import SocialLinks from '../components/SocialLinks';
import Store from '../components/Store';

const Container = styled.div`
  padding: 2rem;
  text-align: center; // Center the content
  font-family: 'DM Serif Display', serif; // Apply the font here
`;



const Image = styled.img`
  max-width: 100%;
  height: auto;
 
`;

const reviews = [

  {
    name: 'Brenda Wilt. "Camp Music."',
    title: 'Civil War Times Illustrated',
    review: 'Just as camp musicians, or ministrel bands, were an essential part of camp life for Civil War soldiers, so they are for modern-day reenactors. One such talented ensemble, the 2nd South Carolina String Band, has produced a video called Far, Far from Home that brings to life not only the sounds, but also the sights of camp life… Filmed on location in Gettysburg, the video really creates the illusion of being in camp with the soldiers, listening to the songs of home.',
    rating: 5,
  },
  {
    name: 'Jim Kushlan. "Fresh from the Past."',
    title: 'Civil War Times Illustrated',
    review: 'Much like hearing Bach’s Brandenburg Concerti performed on period instruments according to a Baroque aesthetic, listening to Civil War songs played on genuine instruments of the period as they would have been in 1860s lets you hear the real sound of a time and place. Still, the real essence of a piece of music is not found in the make of the instruments, but in artful performance done with understanding.',
    rating: 5,
  },
  {
    name: 'Bob Flesher. "The Banjo in the Civil War."',
    title: 'Banjo NewsLetter',
    review: 'It’s beautiful, but with a slight rough edge on it for authenticity. Excellent vocals and harmony and real good instrumentation. The banjo player Joe Ewers plays an authentic 13″ tackhead banjo. And I love the use of the base drum.',
    rating: 5,
  },

  {
    name: 'Will Dennison. “Southern Soldier."',
    title: 'Camp Chase Gazette',
    review: 'A truly remarkable experience. The music is beautifully played and takes you back to the Civil War era.',
    rating: 5,
  },
  {
    name: 'Kathryn Miller Haines',
    title: 'Center for American Music, Stephen Foster Memorial',
    review: 'I’ve heard many interpretations of Foster’s songs over the years, but your group does a fantastic job both with the songs themselves and the intent behind them. I’m duly impressed. By doing such a remarkable job of capturing the joy and pathos of Foster’s lyrics, your performances transport the listener to another time…',
    rating: 5,
  },

  

  // Add more reviews as needed
];

const Home = () => {
  return (
    <Container>
      <div className="flex flex-col items-center h-auto bg-earthyGreen-light">   
        <div className="xl:w-1/2 md:w-3/4 p-2 pb-12 bg-yellow-50 rounded shadow-lg">
          <div className="flex flex-col items-center">
            <div className=" min-w-90 p-0">
              <Image className="max-w-4 lg:my-4 lg:rounded-lg" src="/2ndsouthbandphoto.jpg" alt="2nd South Carolina String Band Band Photo" />
            </div>
            <div className="text-xl pl-2 pr-2">
              <p className="lg:first-letter:text-5xl lg:first-letter:-mx-1 lg:mx-6 text-earthyBrown-dark" >The mission of the 2nd South Carolina String Band is to present Civil War music in as authentic a manner as possible. In their recordings the listener will hear the music of the 19th century played on 19th century period instruments in the appropriate style. This is the music as it truly sounded to the soldiers of the Civil War.</p>
            </div>
          </div>
        </div>
      </div>
   
      <div className="flex flex-col items-center mt-8 h-auto bg-earthyGreen-light">
      <h2 className="text-xl font-bold mb-4">Final Concert</h2>

      <div className="flex flex-col items-center h-auto bg-earthyGreen-light">   
        <div className="xl:w-1/2 md:w-3/4 p-2 pb-12 bg-yellow-50 rounded shadow-lg">
          <div className="flex flex-col items-center">
            <div className=" min-w-90 p-0">
              <Image className="max-w-4 lg:my-4 lg:rounded-lg" src="/concert.jpg" alt="2nd South Carolina String Band Band Photo" />
            </div>
            <div className="text-xl pl-2 pr-2">
              <p className="lg:first-letter:text-5xl lg:first-letter:-mx-1 lg:mx-6 text-earthyBrown-dark" >Join us Friday November 15th, 2024 for the <b>FINAL performance of the 2nd South Carolina String Band.</b> The event will be held at the Gettysburg Middle School and the doors open at 7:30PM. Click <a href="https://lomascenter.org/event/2nd-south-carolina-string-band-concert/" class="text-blue-800">HERE</a> for tickets!
             Can’t make it in-person? You’re in luck! Purchase Livestream access for just $10 by clicking <a href="https://lomascenter.org/register/2nd-south-carolina-string-band-concert-livestream/" class="text-blue-800">HERE</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="flex flex-col items-center mt-8 h-auto bg-earthyGreen-light">
        <h2 className="text-xl font-bold mb-4">Band Store</h2>
       <Store></Store>
        
      </div>


      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl font-bold mb-4">Reviews</h2>
        <div className="flex flex-wrap justify-center gap-4">
          {reviews.map((review, index) => (
            <div key={index} className="w-full text-sm lg:w-1/3 sm:w-3/4 p-2 flex text-left justify-center">
              <Review {...review} />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl font-bold mb-4">Featured Video</h2>
        <YouTubePlayer videoId="NpSR0oI5zy8" /> {/* Replace with your desired video ID */}
      </div>

      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl font-bold mb-4">Follow Us</h2>
        <SocialLinks 
          facebookUrl="https://www.facebook.com/2SCSB" 
          youtubeUrl="https://www.youtube.com/channel/UC6RHX6u-WDoS0EuMgIyfHrA" 
        />
      </div>

    </Container>
  );
};

export default Home;
