import React from 'react';
import { FaStar } from 'react-icons/fa';
import "../App.css";
const Review = ({ name, title, review, rating }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
      <div className="flex items-center mb-2">
        <div className="text-yellow-500 flex">
          {Array(rating)
            .fill()
            .map((_, i) => (
              <FaStar key={i} />
            ))}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-bold">{name}</h3>
          <p className="pt-0 text-sm text-gray-500">{title}</p>
        </div>
      </div>
      <p className="text-gray-800 pt-0 ">{review}</p>
    </div>
  );
};

export default Review;
