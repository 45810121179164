import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  padding-bottom: .2rem;
   margin-top: .2rem;
  font-family: 'DM Serif Display', serif;
  background: white;

  img {
       min-width: 100px; /* Adjust the size as needed */
  }

  h1 {
    margin: 0;
    justify-content:start;
  }

  .subtitle {
    margin: 0;
    justify-content:start;
  }
`;
const HeaderSection = () => {
  return (
    <HeaderContainer>
      <div className="flex flex-col items-center md:flex-row">
      <img className="min-w-lg md:mr-2 my-4 px-4" src="/2nd-south-logo-v.png" alt="2nd South Carolina String Band logo" />

      </div>
    </HeaderContainer>
  );
};

export default HeaderSection;
